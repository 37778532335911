import {connect, useSelector} from 'react-redux';
import {CustomerDetailsForm} from './index';
import {AnyAction} from 'redux';
import {ICustomerSearchParams, IDispatchFromProps, IStateFromProps} from './types';
import {ThunkDispatch} from 'redux-thunk';
import {RootState} from "app/main/rootReducer";
import {ICustomer} from "app/models";
import {debounce, isEmpty} from "lodash-es";

import {
    searchCustomer,
    setBookingNotes,
    setCustomerDetails,
    setIsExistingCustomer,
    setTags
} from 'app/reducers/customerSlice';
import {StateHelperService} from "app/services/helpers/stateHelper.service";
import {IBookingTag} from "shared-types/SharedTypes";
import PhoneNumberService from "shared-services/phone-number-service/index";
import {phoneNumberTypes} from "shared-services/phone-number-service/phoneNumber.types";

const NS = 'CustomerDetailsFormContainer';

const mapStateToProps = (state: RootState): IStateFromProps => {

    const { selectedSchedule, bookingTime } = state.venueGridReducer;
    const activeVenue = state.appInitReducer.activeVenue;
    const customerDetails = state.customerReducer.customerDetails;
    const country = customerDetails.country || state.customerReducer.country || selectedSchedule?.venueDetails?.country || null;
    const phoneWithoutPrefix = state.customerReducer.phoneWithoutPrefix || null;
    const customers = state.customerReducer.customers;
    const tags = state.customerReducer.tags;
    const isExistingCustomer = state.customerReducer.isExistingCustomer;
    const bookingNotes = state.customerReducer.bookingNotes;
    const accountId = state.appInitReducer.accountId;
    const account = state.appInitReducer.account;
    const smsConfirmation = state.paymentReducer.smsConfirmation;
    const emailConfirmation = state.paymentReducer.emailConfirmation;

    const communicationDetails = {
        smsConfirmation,
        emailConfirmation
    }

    const scheduleTags: IBookingTag[] = !isEmpty(selectedSchedule) ? selectedSchedule.tags : []; // ALL tags



    return {
        country: country,
        phoneWithoutPrefix: phoneWithoutPrefix || PhoneNumberService.getInternationPhoneNumberWithoutPrefix(customerDetails.phone, country),
        customerDetails, // to check if the subscribed option be turned on/off by default
        tags: [...account.tags, ...scheduleTags],
        selectedTags: tags,
        accountId,
        customers,
        bookingNotes: bookingNotes,
        isExistingCustomer,
        communicationDetails,
        isStandByListAvailable: bookingTime?.isStandByListAvailable,
        titleSubscription: activeVenue?.widgetSettings.titleSubscription,
        defaultEmailSubscribed: activeVenue?.widgetSettings.defaultEmailSubscribedForDiary,
        widgetFieldSettings: activeVenue?.widgetSettings.widgetFieldSettings,
        venueName: activeVenue.name,
    };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): IDispatchFromProps => {
    return {
        handleUpdate: (customerDetails: ICustomer, tags: IBookingTag[], isValid: boolean, country: string, phoneWithoutPrefix: string) => {
            dispatch(setCustomerDetails({customerDetails, tags, isValid, country, phoneWithoutPrefix}));
        },
        loadLibPhoneNumber: () => {
            dispatch(StateHelperService.loadLibPhoneNumber())
        },
        updateTags: (tags: IBookingTag[]) => {
            dispatch(setTags(tags));
        },
        updateBookingNotes: (notes: string) => {
            dispatch(setBookingNotes(notes));
        },
        searchCustomerVal: debounce((params: ICustomerSearchParams) => {
            dispatch(searchCustomer(params));
        }, 500),
        setIsExistingCustomer: (isExistingCustomer: boolean) => {
            dispatch(setIsExistingCustomer(isExistingCustomer));
        },
    }
};

const CustomerDetailsFormContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomerDetailsForm as any);

export default CustomerDetailsFormContainer;
